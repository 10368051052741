export const PERMISSIONS = {
  MANAGER: [
    "home",
    "managerConfig",
    "stockInventories",
    "stockInventory",
    "createStockInventory",
    "machines",
    "machine",
    "machineInventories",
    "machineInventory",
    "createMachineInventory",
    "editMachineInventory",
    "editStockInventory",
  ],
  STOCK_MANAGER: [
    "home",
    "managerConfig",
    "stockInventories",
    "stockInventory",
    "createStockInventory",
    "machineInventories",
    "machineInventory",
    "createMachineInventory",
    "editMachineInventory",
    "editStockInventory",
  ],
};

export const USER_TYPES = {
  1: "ADMIN",
  2: "MANAGER",
  3: "STOCK_MANAGER",
};
