import store from "@/store/index";
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const { accessToken } = store.getters["auth/userState"];
    if (accessToken) {
      config.headers["Authorization"] = accessToken;
    }
    config.headers["Content-Type"] = "application/json;multipart/form-data";

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    let status = error.response ? error.response.status : null;

    if (status === 401) {
      console.log("Erro 401-necessario refresh token");
      return refreshTokens()
        .then((tokens) => {
          api.defaults.headers["Authorization"] = `${tokens.accessToken}`;
          error.config.headers["Authorization"] = `${tokens.accessToken}`;
          error.config.headers;
          return axios.request(error.config);
        })
        .catch((err) => err);
    }
    return Promise.reject(error);
  }
);

async function refreshTokens() {
  try {
    let { refreshToken } = store.getters["auth/userState"];
    const client = new CognitoIdentityProviderClient({
      region: process.env.VUE_APP_COGNITO_REGION,
    });

    const COGNITO_CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
    const command = new InitiateAuthCommand({
      AuthFlow: "REFRESH_TOKEN_AUTH",
      ClientId: "4gugeequk6erkalb96a63iiimb",
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    });

    const response = await client.send(command);

    if (
      response.AuthenticationResult?.AccessToken &&
      response.AuthenticationResult?.IdToken
    ) {
      let accessToken = response.AuthenticationResult?.AccessToken;
      let idToken = response.AuthenticationResult?.IdToken;
      let tokens = {
        accessToken,
        idToken,
      };

      store.commit("auth/setUser", tokens);
    }

    return {
      token: response.AuthenticationResult?.IdToken,
      accessToken: response.AuthenticationResult?.AccessToken,
    };
  } catch (err) {
    if (err.message == "Invalid Refresh Token") {
      window.location.href = "/";
      store.dispatch("auth/LogOut");
    }
  }
}

export default api;
