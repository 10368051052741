export class HandleErrors {
  message(
    error,
    errorMessageParam = "Entre em contato com o nosso suporte. suporte@snackly.com"
  ) {
    let errorMessage;
    if (error?.response) {
      const { message } = error.response.data;
      errorMessage = message;
    } else if (error?.request) {
      console.log(error.request);
    } else {
      errorMessage = errorMessageParam;
    }
    console.log("ERROR: ", error.message);
    return errorMessage;
  }
}
