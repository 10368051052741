const Product = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Product.vue");
const ProductCreateForm = () =>
  import(/* webpackChunkName: "common" */ "@/components/ProductCreateForm.vue");
const ProductEditForm = () =>
  import(/* webpackChunkName: "common" */ "@/components/ProductEditForm.vue");
const Products = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Products.vue");

const productRoutes = [
  {
    path: "products",
    name: "products",
    component: Products,
    meta: { requiresAuth: true },
  },
  {
    path: "product/:id",
    name: "product",
    component: Product,
    meta: { requiresAuth: true },
  },
  {
    path: "product/edit/:id",
    name: "productEdit",
    component: ProductEditForm,
    meta: { requiresAuth: true },
  },
  {
    path: "products/create",
    name: "createProduct",
    component: ProductCreateForm,
    meta: { requiresAuth: true },
  },
];

export { productRoutes };
