<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright">
        Copyright © {{year}}. Created by
        <a href="https://www.luttos.com" target="_blank" rel="noopener">Luttos</a> - All Rights Reserved.
      </div>
    </div>
  </footer>
</template>
<script>
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  };
</script>
<style>
</style>
