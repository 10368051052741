<template>
  <div
    class="row justify-content-center align-items-center"
    style="height: 100vh"
  >
    <div class="col-12 mb-5">
      <div class="row justify-content-center">
        <div class="col-6 text-center">
          <div class="photo mb-5">
            <img src="/img/icon.png" />
          </div>
          <h1 class="title text-center">Usuário não autorizado</h1>
          <h3 class="text-muted">
            Seu usuário não tem permissão para acessar este conteúdo . Fale com
            seu administrador
          </h3>
          <router-link to="/"
            ><button class="btn btn-primary">
              Página inicial
            </button></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Unauthorized",
};
</script>

<style lang="css" scoped>
.container-unauthorized {
  margin-top: 4rem;
  text-align: center;
}
</style>