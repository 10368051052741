export default {
  namespaced: true,
  state: {
    appUpdate: {
      version: null,
    },
  },
  mutations: {
    storeAppUpdate(state, data) {
      localStorage.setItem("@Snackly:Version", JSON.stringify(data));
      state.appUpdate = data;
    },
    clearUpdateData(state, data) {
      state.appUpdate = data;
    },
  },
  getters: {
    getVersion(state) {
      return state.appUpdate.version;
    },
  },
  actions: {
    loadAppUpdate(context) {
      let versionData = localStorage.getItem("@Snackly:Version");

      if (versionData) {
        context.commit("storeAppUpdate", JSON.parse(versionData));
      }
    },
    clearAppUpdate(context) {
      context.commit("clearUpdateData", {
        version: null,
      });
    },
  },
};
