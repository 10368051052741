const Moviments = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Moviments.vue");

const movimentsRoutes = [
  {
    path: "moviments",
    name: "moviments",
    component: Moviments,
    meta: { requiresAuth: true },
  },
];

export { movimentsRoutes };
