import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import auth from "./auth";
import company from "./company";
import manager from "./manager";
import update from "./update";
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    company,
    manager,
    update,
  },
  plugins: [createPersistedState()],
});

export default store;
