import {
  CognitoIdentityProviderClient,
  GetUserCommand,
  InitiateAuthCommand,
} from "@aws-sdk/client-cognito-identity-provider";

// TODO: NO SIGN UP (CADASTRO DO USUÁRIO) LEMBRAR DE USER O HOOK DO VUE-ROUTER. SE NÃO O MIDDLEWARE DO LOGIN NÃO VAI DEXAR ACESSAR A PÁGINA DE SIGN UP (beforeEnter)
export default {
  namespaced: true,
  state: {
    user: {
      signed: false,
      accessToken: null,
      refreshToken: null,
      idToken: null,
      name: null,
      email: null,
      userId: null,
    },
  },
  mutations: {
    setUser(state, payload) {
      let userData = JSON.parse(localStorage.getItem("userData"));
      state.user = {
        ...userData,
        ...payload,
      };
      localStorage.setItem(
        "userData",
        JSON.stringify({
          ...userData,
          ...payload,
        })
      );
    },
    LogOut(state) {
      state.user = {
        signed: false,
        accessToken: null,
        refreshToken: null,
        idToken: null,
        name: null,
        email: null,
        userId: null,
      };
      for (const [localStorageKey, _] of Object.entries(localStorage)) {
        if (localStorageKey != "@Snackly:Version") {
          localStorage.removeItem(localStorageKey);
        }
      }
    },
  },
  actions: {
    async LogIn(context, payload) {
      const COGNITO_CLIENT_ID = process.env.VUE_APP_COGNITO_CLIENT_ID;
      const client = new CognitoIdentityProviderClient({
        region: process.env.VUE_APP_COGNITO_REGION,
      });
      const command = new InitiateAuthCommand({
        AuthFlow: "USER_PASSWORD_AUTH",
        AuthParameters: {
          USERNAME: payload.email,
          PASSWORD: payload.password,
        },
        ClientId: "4gugeequk6erkalb96a63iiimb",
      });

      const response = await client.send(command);

      let userData = {
        idToken: response.AuthenticationResult?.IdToken,
        refreshToken: response.AuthenticationResult?.RefreshToken,
        accessToken: response.AuthenticationResult?.AccessToken,
      };

      if (response.$metadata.httpStatusCode == 200) {
        const client = new CognitoIdentityProviderClient({
          region: process.env.VUE_APP_COGNITO_REGION,
        });
        const commandGetUser = new GetUserCommand({
          AccessToken: userData.accessToken,
        });

        const responseGetUser = await client.send(commandGetUser);

        const name = responseGetUser.UserAttributes[2]["Value"];
        const email = responseGetUser.UserAttributes[3]["Value"];
        const userId = responseGetUser.Username;
        let userNewData = {
          signed: true,
          name,
          email,
          userId,
          ...userData,
        };

        localStorage.setItem("userData", JSON.stringify(userNewData));
        context.commit("setUser", userNewData);
      }
    },
    async LogOut({ commit }) {
      commit("LogOut");
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.user.signed;
    },
    userState(state) {
      return state.user;
    },
  },
};
