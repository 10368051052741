export default {
  namespaced: true,
  state: {
    company: {
      cnpj: "",
      location_id: "",
      company_id: null,
      corporate_name: "",
      fancy_name: "",
    },
  },
  mutations: {
    storeCompany(state, data) {
      localStorage.setItem("@Snackly:managerCompany", JSON.stringify(data));
      state.company = data;
    },
    clearCompanyData(state, data) {
      state.company = data;
    },
  },
  getters: {
    getManagerCompany(state) {
      return state.company;
    },
  },
  actions: {
    loadManagerCompany(context) {
      let managerCompany = localStorage.getItem("@Snackly:managerCompany");

      if (managerCompany) {
        context.commit("storeCompany", JSON.parse(managerCompany));
      }
    },
    clearCompany(context) {
      context.commit("clearCompanyData", {
        cnpj: "",
        location_id: "",
        company_id: null,
        corporate_name: "",
        fancy_name: "",
      });
    },
  },
};
