<template>
  <div class="update-container" :class="{ '-active': isActive }">
    <div class="content">
      <p class="text-white">
        Uma nova atualização no nosso sistema de gestão está disponível.
      </p>
      <base-button
        id="update-btn"
        size="sm"
        type="primary"
        @click="handleUpdate"
        >Atualizar</base-button
      >

      <i
        class="tim-icons icon-simple-remove"
        @click="() => (this.isActive = false)"
      ></i>
    </div>
  </div>
</template>

<script>

const THREE_MINUTES = 180000;
export default {
  name: "UpdateBar",
  data() {
    return {
      isActive: false,
    };
  },
  async mounted() {
    setInterval(async () => {
      if (this.$store.getters["auth/isAuthenticated"]) {
        const data = await this.getUpdate();
        const { update } = data;
        if (this.hasLocalStorageUpdate()) {
          if (await this.verifyUpdate(update.version)) {
            this.isActive = true;
          }
        } else {
          this.saveUpdate(data);
          this.isActive = true;
        }
      }
    }, THREE_MINUTES);
  },
  methods: {
    async getUpdate() {
      try {
        const { data } = await this.$http.get("update");
        if (data) {
          this.updateData = data;
        }
        return data;
      } catch (err) {
        this.loading = false;
        this.$toast.error(this.$handleErrors.message(err))
      }
    },
    hasLocalStorageUpdate() {
      const getUpdateVersion = JSON.parse(
        localStorage.getItem("@Snackly:Version")
      );
      if (getUpdateVersion) return true;
      return false;
    },
    async verifyUpdate(version) {
      const getUpdateVersion = JSON.parse(
        localStorage.getItem("@Snackly:Version")
      );
      if (getUpdateVersion) {
        if (getUpdateVersion.update.version != version) {
          return true;
        }
      }
      return false;
    },
    saveUpdate(updateData) {
      this.$store.commit("update/storeAppUpdate", updateData);
    },
    handleUpdate() {
      this.isActive = false;
      this.saveUpdate(this.updateData);
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.update-container {
  position: absolute;
  transform: translateY(-100%);
  width: 100%;
  z-index: 9999;
  background: rgb(158, 158, 197);
  text-align: center;
  transition: transform 300ms ease-in;
}

.update-container .content {
  position: relative;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.content .icon-simple-remove {
  cursor: pointer;
  position: absolute;
  right: 28px;
}

.update-container.-active {
  transform: translateY(0%);
}
</style>