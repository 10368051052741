import VueRouter from "vue-router";
import routes from "./routes";

import store from "@/store/index";
import { PERMISSIONS } from "../services/permissions";

// configure router
const router = new VueRouter({
  mode: "history",
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

function hasPermissions(isAdmin, userType, routerName) {
  return isAdmin ? isAdmin : PERMISSIONS[userType].includes(routerName);
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const isAdmin = store.getters["manager/isManagerAdmin"];
    const getUser = store.getters["manager/getManager"];
    if (store.getters["auth/isAuthenticated"]) {
      if (hasPermissions(isAdmin, getUser.user_type, to.name)) {
        next();
      } else {
        next({ name: "Unauthorized" });
      }
    } else {
      next("/login");
    }
  } else {
    next();
  }
});
export default router;
