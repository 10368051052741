<template>
  <div>
    <update-bar></update-bar>
    <notifications></notifications>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import UpdateBar from "./components/UpdateBar.vue";
export default {
  components: {
    UpdateBar,
  },
  methods: {
    disableRTL() {
      if (!this.$rtl.isRTL) {
        this.$rtl.disableRTL();
      }
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName("html")[0];
      root.classList.toggle("nav-open");
    },
  },
  async mounted() {
    this.$watch("$route", this.disableRTL, { immediate: true });
    this.$watch("$sidebar.showSidebar", this.toggleNavOpen);
  },
  created() {
    this.$store.dispatch("manager/loadManager");
    this.$store.dispatch("company/loadManagerCompany");
    this.$store.dispatch("update/loadAppUpdate");
  },
};
</script>

<style lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  gap: 30px;
  font-size: 1.1rem;
}

.pagination a {
  color: #fff;
}

.pagination .page-item {
  width: 40px;
  text-align: center;
}

.page-item.active {
  border: 1px solid #fdfd96;
  border-radius: 4px;
}

.page-item.active a {
  font-weight: bold;
  color: #fdfd96;
}
</style>
