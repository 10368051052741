const Report = () =>
  import(/* webpackChunkName: "common" */ "@/pages/Report.vue");

const StockMetrics = () =>
  import(/* webpackChunkName: "common" */ "@/pages/StockMetrics.vue");

const reportRoutes = [
  {
    path: "report",
    name: "report",
    component: Report,
    meta: { requiresAuth: true },
  },
  {
    path: "report/stock/metrics",
    name: "stockMetrics",
    component: StockMetrics,
    meta: { requiresAuth: true },
  },
];

export { reportRoutes };
