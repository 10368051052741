import { USER_TYPES } from "../services/permissions";

export default {
  namespaced: true,
  state: {
    manager: {
      manager_id: null,
      role: null,
      active: null,
      cpf: null,
      name: null,
      birth_date: null,
      phone_number: null,
      email: null,
      companies: [],
      user_type: null,
    },
  },
  mutations: {
    storeManager(state, data) {
      localStorage.setItem("@Snackly:Manager", JSON.stringify(data));
      data.user_type = USER_TYPES[data.role_id];
      state.manager = data;
    },
    clearManagerData(state, data) {
      state.manager = data;
    },
  },
  getters: {
    getManager(state) {
      return state.manager;
    },
    isManagerAdmin(state) {
      if (state.manager.role_id == 1) return true;
      return false;
    },
  },
  actions: {
    loadManager(context) {
      let managerData = localStorage.getItem("@Snackly:Manager");

      if (managerData) {
        context.commit("storeManager", JSON.parse(managerData));
      }
    },
    clearManager(context) {
      context.commit("clearManagerData", {
        manager_id: null,
        role: null,
        active: null,
        cpf: null,
        name: null,
        birth_date: null,
        phone_number: null,
        email: null,
        companies: [],
        user_type: null,
      });
    },
  },
};
