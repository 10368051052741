import Notify from "@/components/NotificationPlugin";
import SideBar from "@/components/SidebarPlugin";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import Toast, { POSITION } from "vue-toastification";
import loader from "vue-ui-preloader";
import Unicon from "vue-unicons/dist/vue-unicons-vue2.umd";
import RTLPlugin from "./RTLPlugin";
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";

import "vue-toastification/dist/index.css";

//css assets
import "@/assets/css/nucleo-icons.css";
import "@/assets/demo/demo.css";
import "@/assets/sass/black-dashboard.scss";

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(Notify);
    Vue.use(RTLPlugin);
    Vue.use(VueRouter);
    Vue.use(RouterPrefetch);
    Vue.use(loader);
    Vue.use(Unicon);
    Vue.use(Toast, {
      position: POSITION.TOP_RIGHT,
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false,
    });
  },
};
