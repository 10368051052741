/* eslint-disable no-console */

import { register } from "register-service-worker";
if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: {
      scope: process.env.BASE_URL,
    },
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      // const modal = document.getElementById("exampleModal");
      // const updateBtn = document.getElementById("update-btn");
      // const modalInstance = new bootstrap.Modal(modal);
      // modalInstance.show();

      // updateBtn.addEventListener("click", () => {
      //   registration.waiting.postMessage("SKIP_WAITING");
      //   caches.keys().then(function (names) {
      //     for (let name of names) caches.delete(name);
      //   });
      //   window.location.reload();
      // });
      console.log("window reloaded!");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}
